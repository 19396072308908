<template>
  <div>
    <Alert type="error" v-if="detail&&detail.check&&!detail.check.amendId">当前任务未生成整改单，此报告将无法显示风险隐患信息，请先<a @click="amendFlag=true" href="javascript:;">生成整改单</a>。</Alert>
    <Alert type="warning" v-if="templateList.length<=0">当前任务无模板可用，如需开通请联系运维人员！</Alert>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
      <FormItem label="模板名称" prop="templateId" v-if="templateList.length>0">
        <Select v-model="form.templateId" placeholder="模板名称" transfer>
          <Option v-for="(item) in templateList" :value="item.id" :key="item.id">{{ item.name }}-{{ item.orgName }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea" v-if="templateList.length>0">
      <Button type="primary" @click="addReportFlag=true" v-if="templateList.length<=0">创建报告</Button>
      <Button type="primary" @click="putOut" :disabled="templateList.length<=0">下载报告</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="amendFlag" :width="1000" title="生成整改单">
      <Edit :parentID="checkId" :taskType="`${detail.type || '1'}`"></Edit>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {templateList} from "@/api/report";
import {blobDownFile} from 'ys-admin'
import Edit from "@/views/rectifications/Edit";
import {checkInfo} from "@/api/check";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    type: {type: Number, default: 1},
    serveOrgId: {type: Number, default: 0},
    checkId: {type: Number, default: 0},
    name: {type: String, default: ''},
  },
  components: {Edit},
  data() {
    return {
      ruleValidate: {
        desc: [
          {required: true, type: 'string', message: '请填写检查结论', trigger: 'blur'}
        ],
      },
      amendFlag: false,
      addReportFlag: false,
      detail: {},
      templateList: [],
      form: {
        checkId: '',
        templateId: '',
      },
    }
  },
  mounted() {
    this.form.checkId = this.checkId;
    this.init();
  },
  methods: {
    putOut() {
      this.modal.spinShow = true;
      this.$axios({
        method: 'GET',
        responseType: 'blob',
        url: 'report/Gen?checkId=' + this.checkId + '&templateId=' + this.form.templateId,
      }).then((res) => {
        blobDownFile(res.data, (this.name || this.$formatDate(new Date(), 'yyyy-MM-dd')) + '的报告.docx');
        this.close();
        this.$emit('on-ok');
      }).catch(() => {
        this.modal.spinShow = false;
      })
    },
    init() {
      checkInfo({id: this.checkId}).then((checkInfo) => {
        if (checkInfo.code === 200) {
          templateList({orgId: this.serveOrgId, standardId: checkInfo.warehouseId, type: this.type, limit: -1, status: 1}).then((res) => {
            this.templateList = res.data.list || [];
            this.form.templateId = this.templateList.length > 0 ? this.templateList[0].id : '';
          })
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
</style>
