<template>
  <div>
    <ys-detail :icon="detailIcon" :loading="loading" :name="detail.number">
      <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('报告状态') }}</Tag>
      <template slot="brief">
        <ys-cell title="投保机构" :label="detail.enterpriseName"/>
        <ys-cell title="报告类型" :label="detail.typeName"/>
        <ys-cell title="创建时间" :label="detail.createdAt|formatDate"/>
        <ys-cell title="报告结论" :label="detail.desc"/>
      </template>
      <template slot="main-left">
        <Tabs type="card" v-model="tab" :key="detail.id">
          <Tab-pane label="报告明细" icon="ios-list-box-outline" name="1">
            <baseInfo :detailInfo="detail" v-if="tab==='1'" @on-ok="getDetail"></baseInfo>
          </Tab-pane>
          <Tab-pane :label="(detail.type===9?'风险':'隐患')+'列表'" icon="md-list-box" name="2" v-if="detail.type!==7">
            <problems :assembly="true" :tableHeight="pageListHeight" :mid="detail.checkId" v-if="tab==='2'&&detail.type!==9"></problems>
            <risk :assembly="true" :tableHeight="pageListHeight" :mid="detail.checkId" v-if="tab==='2'&&detail.type===9"></risk>
          </Tab-pane>
          <Tab-pane label="审核记录" icon="ios-map-outline" name="3">
            <check-list :tableHeight="pageListHeight" :mid="detail.id" v-if="tab==='3'"></check-list>
          </Tab-pane>
        </Tabs>
      </template>
      <template slot="main-right">
        <h1 class="h1Title">暂无开放</h1>
        <!--        <chart :key="detail.id" :mid="detail.checkId"></chart>-->
      </template>
    </ys-detail>
  </div>
</template>
<script>
import detail from '@/mixins/detail'
import {getReportInfo} from '@/api/report'
import problems from "@/views/problems/index";
import risk from "@/views/service/identification/risk/index";
// import Chart from "@/views/task/detail/chart";
import baseInfo from "@/views/report/detail/baseInfo";
import CheckList from "@/views/report/detail/checkList";

export default {
  name: 'reportDetail',
  mixins: [detail],
  components: {
    CheckList,
    risk,
    // Chart,
    baseInfo,
    problems,
  },
  data() {
    return {
      url: '',
      modShow: false,
      loading: false,
      checkStatusFlag: false,
      fileList: [],
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.detail = {}
      getReportInfo({id: this.mid}).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
          this.tab = this.tab === '2' && this.detail.type === 7 ? '1' : this.tab;
        }
      }).catch(() => {
        this.loading = false;
      })
    },
  }
}
</script>
