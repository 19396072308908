<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/check/member/List"
        :header="false"
        :params="params"
        :tableHeight="resultHeight+60"
        :multiColumn="false"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js';

export default {
  name: '',
  mixins: [pageList],
  data() {
    return {
      headerColumns: [
        {width: 50, type: 'index', title: '序号', align: 'center'},
        {width: 120, title: '人员姓名', key: 'name', align: 'center'},
        {minWidth: 180, title: '所属机构', key: 'orgName', align: 'left'},
        {width: 180, title: '电话', key: 'phone', align: 'center', renderConfig: {type: 'phoneDesensitise'}},
        {width: 180, title: '职位', key: 'position', align: 'center', },
        {width: 180, title: '签到时间', key: 'signAt', align: 'center'},
      ],
      params: {
        eventId:''
      },
    }
  },
  mounted() {
    this.params.checkId = this.mid;
    this.getList()
  },
  methods: {}

}
</script>
