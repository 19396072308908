<template>
  <div class="home">
    <ys-page-list
        ref="table"
        keyID="DCBA434FCEC75AC85B0A4A522DF0072C"
        action="/report/List"
        :params="params"
        :tableLoading="loading"
        :tableHeight="height-245"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :multiColumn="multiColumn"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="服务类型" prop="type">
          <Select v-model="params.type" clearable placeholder="服务类型" style="width: 120px;">
            <Option v-for="(item,index) in checkType" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="reportFlag" :width="500" title="下载报告">
      <doReport
          @on-ok="getList"
          :mid="detailID"
          :checkId="tableSelectItem.length===1?tableSelectItem[0].checkId:0"
          :serveOrgId="tableSelectItem.length===1?tableSelectItem[0].serveOrgId:0"
          :name="tableSelectItem.length===1?tableSelectItem[0].projectName:''"></doReport>
    </ys-modal>
    <ys-modal v-model="checkStatusFlag" :width="500" title="审批报告">
      <checkStatus @on-ok="getList" :mid="tableSelectItem.length===1?tableSelectItem[0].checkId:0"></checkStatus>
    </ys-modal>
    <!-- 接口返回的数据中不存在reportId -->
    <ys-modal v-model="addReportFlag" :width="800" :title="'修改报告'">
      <ReportAdd
          :midData="tableSelectItem" @on-ok="getList"
          :checkId="tableSelectItem.length===1?tableSelectItem[0].checkId:0"></ReportAdd>
    </ys-modal>
    <ys-drawer v-model="detailFlag">
      <check-detail :mid="tableSelectItem.length===1?tableSelectItem[0].checkId:0"></check-detail>
    </ys-drawer>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import Detail from "./detail.vue";
import doReport from "./doReport";
import {getReportInfo, reportCommitCheck} from "@/api/report";
import ReportAdd from "@/views/report/addReport";
import checkStatus from "@/views/report/checkStatus";
import checkDetail from "@/views/task/detail";
import {getCommentList} from "@/api/amend";

export default {
  name: '',
  mixins: [pageList],
  props: {
    checkStatusList: {type: Array, default: () => []},
    status: {type: [Number, String], default: ''},
    isCheckReport: {type: [Number, String], default: ''},
  },
  components: {
    checkDetail,
    checkStatus,
    doReport,
    ReportAdd,
    Detail,
  },
  data() {
    return {
      detailFlag: false,
      addReportFlag: false,
      reportFlag: false,
      checkStatusFlag: false,
      checkFlag: false,
      params: {
        type: '',
        checkStatus: '',
        status: '',
        isCheckReport: '',
        amendId: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 220, title: '报告编号', key: 'number', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 110, title: '报告类型', key: 'type', align: 'left', renderConfig: {type: 'baseData', parentName: '子计划检查类型'}},
        {minWidth: 130, title: '任务名称', key: 'checkName', align: 'left'},
        {minWidth: 130, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 120, title: '生成时间', key: 'createdAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '提交时间', key: 'publishedAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 250, title: '投保机构', key: 'enterpriseName', align: 'left'},
        {width: 120, title: '状态', key: 'status', align: 'left', renderConfig: {type: 'baseData', parentName: '报告状态'}},
        {width: 120, title: '审核状态', key: 'checkStatus', align: 'left', renderConfig: {type: 'baseData', parentName: '报告审核状态'}},
        {width: 160, title: '签到时间', key: 'signTime', align: 'center',  renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}, isShow: this.type !== 9, display: 'inline-block'},
        {width: 160, title: '签退时间', key: 'signOutTime', align: 'center',  renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}, isShow: this.type !== 9, display: 'inline-block'},
        {width: 150, title: '服务时长', key: 'serviceDuration', align: 'left', isShow: this.type !== 9, display: 'inline-block'},
      ]
    }
  },
  computed: {
    multiBtn() {
      const {tableSelectItem = []} = this;
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              title: '查看报告',
              icon: 'md-eye',
              disabled: tableSelectItem.length !== 1
            },
            {
              click: () => this.addReportFlag = true,
              title: '修改报告',
              icon: 'ios-list-box-outline',
              disabled: tableSelectItem.length !== 1 || tableSelectItem[0].status !== 0
            },
            {
              click: () => this.reportFlag = true,
              title: '下载报告',
              icon: 'md-copy',
              disabled: tableSelectItem.length !== 1
            },
          ]
        },
        {
          hiddenBtnIcon: true,
          children: [
            {
              click: () => this.checkProblems(),
              title: '提交',
              icon: 'md-cloud-upload',
              disabled: tableSelectItem.length !== 1 || tableSelectItem[0].status === 1 || [10, 30, 50].indexOf(tableSelectItem[0].checkStatus) < 0,
            },
            {
              click: () => this.checkStatusFlag = true,
              title: '审批报告',
              icon: 'md-cloud-upload',
              disabled: tableSelectItem.length !== 1 || tableSelectItem[0].checkStatus !== 20,
            },
          ]
        }
      ]
    }
  },
  mounted() {
    this.params.checkStatusList = this.checkStatusList;
    this.params.isCheckReport = this.isCheckReport;
    this.params.status = this.status;
    this.getList()
  },
  methods: {
    getList(nextType) {
      this.tableSelectItem = [];
      this.$refs['table'].selectAll(false);
      this.$refs['table'].getList(nextType);
      this.$emit('on-ok')
    },
    async submitReport() {
      const {tableSelectItem = []} = this;
      reportCommitCheck({checkId: tableSelectItem[0].checkId, type: this.tableSelectItem[0].type}).then((res) => {
        if (res.code === 200) {
          this.$Notice.success({
            title: '操作成功！'
          });
          this.getList();
        }
        this.$Modal.remove()
      });
    },
    checkProblems() {
      if (this.tableSelectItem[0].type >3) {
        this.commit();
      } else {
        this.$Spin.show();
        getCommentList({checkId: this.tableSelectItem[0].checkId, limit: -1}).then(res => {
          let errorList = [], content = '';
          (res.data.list || []).forEach(item => {
            item.fileList = item.fileList || [];
            const errorItem = [];
            if (item.fileList.length === 0) errorItem.push("附件")
            if (!item.riskGrade) errorItem.push("风险等级")
            if (!item.accidentType) errorItem.push("可能导致的事故类型")
            if (!item.remarksText) errorItem.push("风险描述")
            if (!item.remarksRisk) errorItem.push("风险依据")
            if (!item.remarksSuggest) errorItem.push("整改建议")
            if (errorItem.length > 0) errorList.push({itemName: item.itemName, errorItem})
          })
          if (errorList.length > 0) {
            errorList.forEach((item, index) => {
              content = content + (index + 1) + '、【' + item.itemName + '】的 <span style="color:#ff0900">' + item.errorItem.join('、') + '</span> 等未填写完整；<br/>'
            })
            this.$Modal.remove();
            window.setTimeout(() => {
              this.$Modal.confirm({
                okText: '前往修改',
                width: 500,
                cancelText: '关闭',
                title: '风险必填项检查失败！',
                content: content + '请前往任务详情查看风险信息，再仔细检查并完善信息！',
                onOk: () => {
                  this.detailFlag = true;
                }
              });
            }, 300);
          } else {
            this.commit();
          }
          this.$Spin.hide();
        }).catch(() => {
          this.$Spin.hide();
        })
      }
    },
    commit() {
      const {checkStatus, isInsCheckReport, isCheckReport, insuranceOrgName} = this.tableSelectItem[0];
      let msg = '';
      if (isCheckReport) {
        if (checkStatus === 10 || checkStatus === 30 || checkStatus === 50) {
          /*提交给专家*/
          msg = "<div style='color:#ff0900'>本次将提交给专家组组长审核！</div>";
        }
      } else if (isInsCheckReport) {
        /*提交给保险公司审核*/
        msg = `<div style='color:#de4e00'>本次将提交给保险公司(${insuranceOrgName})审核！</div>`;
      } else {
        msg = `<div style='color:#007911'>本次将提交给保险公司(${insuranceOrgName})，无需审核！</div>`;
      }
      this.$Modal.confirm({
        title: '提示',
        content: (this.tableSelectItem[0].type >= 2 ? '确定要提交报告么？' : '请确认项目阶段、五牌一图、单体建筑物是否更新！提交后将无法录入风险和生成整改单！') + msg,
        okText: '确定',
        loading: true,
        cancelText: '取消',
        onOk: () => {
          getReportInfo({id: this.detailID}).then((res) => {
            if (res.code === 200) {
              const {fileList = []} = res.data;
              if (!fileList || fileList.length === 0) {
                this.$Notice.error({
                  title: '提示',
                  desc: '还没有上传报告附件，请先上传！'
                });
                this.$Modal.remove();
                this.addReportFlag = true;
                this.detailFlag = true;
              } else {
                this.submitReport();
              }
            }
          })
        }
      });
    }
  }
}
</script>
