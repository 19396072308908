<template>
  <div class="report">
    <Tabs v-model="tab" type="card" :animated="false">
      <TabPane :label="'所有报告('+total.all+')'" name="1">
        <report @on-ok="getTotal" v-if="tab==='1'"></report>
      </TabPane>
      <TabPane :label="'待提交审核('+total.waitCheck+')'" name="2">
        <report @on-ok="getTotal" v-if="tab==='2'" :status="0" :isCheckReport="1" :checkStatusList="[10,30,50]"></report>
      </TabPane>
      <TabPane :label="'待审核('+total.wait+')'" name="3">
        <report @on-ok="getTotal" v-if="tab==='3'" :checkStatusList="[20,40]"></report>
      </TabPane>
      <TabPane :label="'已完成('+total.finish+')'" name="5">
        <report @on-ok="getTotal" :status="1" v-if="tab==='5'" :checkStatusList="[99]"></report>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import Report from "@/views/report/report";
import {all, get} from 'ys-admin'

export default {
  name: "index",
  components: {Report},
  data() {
    return {
      tab: '1',
      total: {
        all: 0,
        waitCheck: 0,
        wait: 0,
        finish: 0,
      },
    }
  },
  created() {
    this.getTotal();
  },
  methods: {
    getTotal() {
      all([
        get('/report/Count', {noPending:true,}),
        get('/report/Count', {noPending:true,isCheckReport: 1, status: 0, checkStatusList: [10, 30, 50]}),
        get('/report/Count', {noPending:true,checkStatusList: [20, 40]}),
        get('/report/Count', {noPending:true,checkStatusList: [99]}),
      ]).then(res => {
        this.total.all = res[0].data || 0;
        this.total.waitCheck = res[1].data || 0;
        this.total.wait = res[2].data || 0;
        this.total.finish = res[3].data || 0;
      })
    },
  }
}
</script>

<style scoped lang="less">
.report {
  padding: 10px;
}
</style>