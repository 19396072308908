<template>
  <div class="report">
    <Tabs v-model="tab" type="card" :animated="false">
      <TabPane :label="'所有整改单('+total.all+')'" name="1">
        <rectifications :tableHeight="detailHeight ? detailHeight : (height - 250)" @on-ok="getTotal" v-if="tab==='1'" :checkId="checkId"></rectifications>
      </TabPane>
      <TabPane :label="'待提交审核('+total.waitCheck+')'" name="2">
        <rectifications :tableHeight="detailHeight ? detailHeight : (height - 250)" @on-ok="getTotal" v-if="tab==='2'" :checkId="checkId" :status="1" :isCheckReport="1" :checkStatusList="[10,30]"></rectifications>
      </TabPane>
      <TabPane :label="'待审核('+total.wait+')'" name="3">
        <rectifications :tableHeight="detailHeight ? detailHeight : (height - 250)" @on-ok="getTotal" v-if="tab==='3'" :checkId="checkId" :checkStatusList="[20]"></rectifications>
      </TabPane>
      <TabPane :label="'审核完成('+total.finish+')'" name="5">
        <rectifications :tableHeight="detailHeight ? detailHeight : (height - 250)" @on-ok="getTotal" v-if="tab==='5'" :checkId="checkId" :checkStatusList="[40]"></rectifications>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import {all, get} from 'ys-admin'
import Rectifications from "./rectifications";
import {mapGetters} from 'vuex';

export default {
  name: "index",
  components: {Rectifications},
  props: {
    checkId: {type: [Number,String], default: ''},
    detailHeight: {type: [Number,String], default: ''}
  },
  data() {
    return {
      tab: '1',
      total: {
        all: 0,
        waitCheck: 0,
        wait: 0,
        finish: 0,
        checkId: '',
      },
    }
  },
  computed: {
    ...mapGetters(['height']),
  },
  created() {
    this.getTotal();
  },
  methods: {
    getTotal() {
      all([
        get('/amend/Count', {noPending:true,checkId: this.checkId}),
        get('/amend/Count', {noPending:true,isCheckReport: 1, status: 0, checkStatusList: [10, 30],checkId: this.checkId}),
        get('/amend/Count', {noPending:true,checkStatusList: [20],checkId: this.checkId}),
        get('/amend/Count', {noPending:true,checkStatusList: [40],checkId: this.checkId}),
      ]).then(res => {
        this.total.all = res[0].data || 0;
        this.total.waitCheck = res[1].data || 0;
        this.total.wait = res[2].data || 0;
        this.total.finish = res[3].data || 0;
      })
    },
  }
}
</script>

<style scoped lang="less">
.report {
  padding: 10px;
}
</style>