
<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="90">
      <FormItem :label="'专家姓名'">
        <Input disabled v-model="form.expertName" :placeholder="'专家姓名'">
          <Button slot="append" @click="expertFlag=true">选择</Button>
        </Input>
      </FormItem>
      <FormItem label="本次费用">
        <InputNumber v-model="form.cost" placeholder="本次费用" :min="0" style="width: 100%"/>
      </FormItem>
      <!-- <FormItem label="组长">
        <i-switch :disabled="isLeader" v-model="form.isLeader" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem> -->
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/check/user/AddExpert')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="expertFlag" :width="1200" :title="'选择专家'">
      <MyExperts :midData="midData" @on-ok="getData"></MyExperts>
    </ys-modal>
  </div>
</template>

<script>
import edit from '@/mixins/edit'

export default {
  name: '',
  mixins: [edit],
  props: {
    isLeader: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'MyExperts': () => import('@/components/MyExperts.vue')
  },
  data() {
    return {
      form: {
        id: '',
        expertName: '',
        expertId: '',
        cost: null,
        // isLeader: 0,
      },
      expertFlag: false
    }
  },
  mounted() {
    if(this.isLeader) {
      this.$Message.warning('必须指定一专家为组长！默认第一位');
      this.form.isLeader = 1
    }
  },
  methods: {
    getData(data) {
      this.form.id = this.midData[0].id
      this.form.expertName = data[0].iname
      this.form.expertId = data[0].id
      this.form.cost = null
      this.form.isLeader = 0
    }
  }
}
</script>
<style scoped>

</style>
