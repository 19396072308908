<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/report/log/List"
        :params="params"
        :header="false"
        :multiColumn="false"
        @on-selection-change="checkItem"
        :tableHeight="tableHeight+60"
        :headerColumns="headerColumns">
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: 'checkList',
  mixins: [pageList],
  data() {
    return {
      params: {
        projectId: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {width: 180, title: '审核时间', key: 'createdAt', align: 'center'},
        {width: 150, title: '审核结果', key: 'title', align: 'center'},
        {width: 120, title: '审核人', key: 'userName', align: 'center'},
        {minWidth: 200, title: '审批意见', key: 'desc', align: 'left'},
        {
          minWidth: 200, title: '附件', key: 'fileList', align: 'center', render: (h, params) => {
            const {fileList = []} = params.row;
            const file = (fileList ? fileList.split(',') : []).map(item => parseInt(item))
            return h('ys-file-list', {
              attrs: {
                class: 'tableFileList ys-file-list',
              },
              props: {
                fileIds: file, type: 'onlyFile', width: 40, height: 40, gutter: 8,
              },
            })
          }
        },
      ]
    }
  },

  mounted() {
    this.params.reportId = this.mid;
    this.getList()
  },
  methods: {}
}
</script>
<style lang="less">
.tableFileList {
  padding-bottom: 10px;

  li {
    margin: 10px 0px 0 0;
  }
}
</style>
