<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="90">
      <FormItem :label="'接收人'">
        <Select v-model="form.targetExpertId" transfer class="selectMultiple" clearable placeholder="接收人">
          <Option v-for="item in ExpertsList" :value="item.expertId" :key="item.expertId">{{ item.iname }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="原因" prop="remarks">
        <Input v-model="form.remarks" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 5}" placeholder="原因"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {checkExpertList} from "@/api/check";

export default {
  name: '',
  mixins: [edit],
  props: {},
  components: {},
  data() {
    return {
      form: {
        checkId: '', // 任务id
        expertId: '', // 当前组长id
        targetExpertId: '', // 被转移专家id
        remarks: '',
      },
      ExpertsList: [],
      Leader: {},
    }
  },
  async mounted() {
    await this.getExpert()
    // 当前账号专家是这个任务的组长才可以进行对应操作
    if(this.userInfo.id !== this.Leader.userId) {
      this.$Notice.warning({
        title: '警告!',
        desc: '只有任务的专家组长可以进行操作!'
      });
    }
    this.form.checkId = this.midData[0].id
    this.form.expertId = this.Leader.expertId
  },
  methods: {
    async getExpert() {
      await checkExpertList({checkId: this.midData[0].id}).then(res => {
        this.ExpertsList = (res.data?.list || []).filter(item => item.isLeader === 0)
        this.Leader = (res.data?.list || []).filter(item => item.isLeader === 1)[0]
      })
    },
    save() {
      if(this.userInfo.id !== this.Leader.userId) {
        this.$Notice.warning({
          title: '警告!',
          desc: '只有任务的专家组长可以进行操作!'
        });
        return
      }
      this.ok('/check/expert/Transfer')
    }
  }
}
</script>
<style scoped>

</style>
