<template>
  <div class="home">
    <ys-page-list
      ref="table"
      action="/check/doc/List"
      :params="params"
      :tableHeight="resultHeight + 100"
      :header="false"
      :footer="false"
      @on-selection-change="checkItem"
      :multiBtn="multiBtn"
      :headerColumns="headerColumns"
    >
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="项目名称、任务名称等" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1000" :title="'录入检查'">
      <Edit @on-ok="getList" :midData="tableSelectItem"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from '@/views/task/detail/Inspection/Edit'

export default {
  name: '',
  mixins: [pageList],
  components: {
    Edit
  },
  props: {},
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 400, title: '需检查资料', key: 'name', align: 'center', render: (h, params) => {
            return h('span', params.row.itemList.map(item => item.name).join())
          }
        },
        {minWidth: 150, title: '检查结果', key: 'result', align: 'center'},
        {minWidth: 300, title: '附件', key: 'projectName', align: 'center', render: (h, params) => {
          return h('ys-file-list', {
            props: {
              fileList: (params.row.itemList || []).map(item => item.fileList).flat(Infinity).filter(item => item), type: 'onlyFile', width: 40, height: 40, gutter: 5,
            },
          })
        }},
      ],
      modShow: false,
      params: {
        keyword: '',
      }
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '录入检查',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1 || this.midData[0].status !== 5
            },
          ]
        },
      ]
    }
  },
  mounted() {
    this.params.checkId = this.mid
    this.getList();
  },
  methods: {}

}
</script>
