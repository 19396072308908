<template>
    <div :style="{maxHeight:baseInfoHeight+'px'}" style="overflow-y: auto">
      <ys-cell-group title="">
        <ys-cell title="无法服务原因" :label="detail.canNotServiceStatus|getBaseDataName('无法服务原因')" block/>
        <ys-cell title="无法服务说明" :label="detail.canNotServiceReason" block/>
        <p class="file">附件:</p>
        <template v-for="(file) in detail.fileList">
          <ys-file-list :key="file.id" :fileList="[file]" :width="70" :height="70" :gutter="1"></ys-file-list>
        </template>
      </ys-cell-group>
    </div>
  </template>
  
  <script>
  import baseInfo from "@/mixins/baseInfo";
  
  export default {
    name: "baseInfo",
    mixins: [baseInfo],
    props: {midData: {type: Array, default:() => []}},
    data() {
      return {
        detail: {},
      }
    },
    mounted() {
      this.detail = this.midData[0].cannotServiceLogs[0];
    },
    methods: {
      
    }
}
  </script>
  
  <style scoped lang="less">
 .file {
    margin: 10px;
    width: 100%;
  }
  </style>