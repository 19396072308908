<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/amend/List"
        :params="params"
        :multiBtn="multiBtn"
        :tableHeight="tableHeight"
        keyID="F7A0BA5C7EC025F12741ABAA219F92E1"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="整改单类型" prop="type" show>
          <Select clearable v-model="params.type" style="width: 140px;">
            <Option v-for="(item,index) in baseData['整改单类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="状态" prop="status" show>
          <Select clearable v-model="params.status" style="width: 100px;">
            <Option v-for="(item,index) in baseData['整改单状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="提交时间" prop="daterange">
          <ys-date-picker type="daterange" clearable v-model="params.daterange" style="width: 220px" placeholder="时间周期"></ys-date-picker>
        </FormItem>
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="关键词" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <detail :mid="detailID"></detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1200" :title="'新增/修改整改单'">
      <Edit @on-ok="getList" :mid="detailID"></Edit>
    </ys-modal>
    <ys-modal v-model="checkStatusFlag" :width="500" title="审批整改单">
      <checkStatus @on-ok="getList" :mid="detailID"></checkStatus>
    </ys-modal>
    <ys-modal v-model="downShow" :width="400" title="下载整改建议书">
      <downFile
          @on-ok="getList"
          :type="2"
          :mid="detailID"
          :checkId="tableSelectItem.length===1?tableSelectItem[0].checkId:''"
          :serveOrgId="tableSelectItem.length===1?tableSelectItem[0].serverOrgId:''"></downFile>
    </ys-modal>
    <ys-drawer v-model="detailFlag">
      <check-detail :mid="tableSelectItem.length===1?tableSelectItem[0].checkId:''"></check-detail>
    </ys-drawer>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js';
import Edit from "@/views/rectifications/Edit";
import detail from "@/views/rectifications/detail";
import downFile from "@/views/rectifications/downFile";
import {amendCommit, getCommentList} from "@/api/amend";
import checkStatus from "@/views/rectifications/checkStatus";
import checkDetail from "@/views/task/detail";

export default {
  name: 'rectifications',
  mixins: [pageList],
  props: {
    checkStatusList: {type: Array, default: () => []},
    status: {type: [Number, String], default: ''},
    isCheckReport: {type: [Number, String], default: ''},
    checkId: {type: [Number,String], default: ''}
  },
  components: {
    checkDetail,
    checkStatus,
    Edit,
    downFile,
    detail,
  },
  watch: {
    'params.daterange'() {
      this.params.startAt = this.params.daterange[0] ? this.params.daterange[0] + ' ' + '00:00:00' : ''
      this.params.endAt = this.params.daterange[1] ? this.params.daterange[1] + ' ' + '23:59:59' : ''
    },
  },
  data() {
    return {
      params: {
        orgId: '',
        checkStatusList: [],
        isCheckReport: '',
        keyword: '',
        status: '',
        daterange: [],
        startAt: '',
        endAt: '',
        checkId: '',
      },
      checkFlag: false,
      checkStatusFlag: false,
      downShow: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 200, title: '整改单编号', key: 'code', align: 'left'},
        {minWidth: 250, title: '整改标题', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 150, title: '类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '整改单类型'}},
        {minWidth: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 250, title: '服务单位', key: 'serverOrgName', align: 'left'},
        {width: 160, title: '打卡日期', key: 'serveTime', align: 'left', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {width: 160, title: '提交时间', key: 'publishedAt', align: 'left', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {width: 100, title: '风险数量', key: 'commentCount', align: 'center'},
        {width: 100, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '整改单状态'}},
        {width: 120, title: '审核状态', key: 'checkStatus', align: 'left', renderConfig: {type: 'baseData', parentName: '整改单审核状态'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              title: '查看详情',
              // targetUrl: '/main/rectifications/detail/' + (this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null),
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              iconColor: '#19be6b',
              title: '修改',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 1
            },
            {
              click: () => this.downShow = true,
              title: '下载整改建议书',
              icon: 'md-cloud-download',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 0
            },
          ]
        },
        {
          hiddenBtnIcon: true,
          children: [
            {
              click: () => this.commit(),
              title: '提交',
              icon: 'md-cloud-upload',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 1 || [10, 30, 50].indexOf(this.tableSelectItem[0].checkStatus) < 0,
            },
            {
              click: () => this.checkStatusFlag = true,
              title: '审批整改单',
              icon: 'md-cloud-upload',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].checkStatus !== 20,
            },
          ]
        },
      ]
    }
  },
  mounted() {
    this.params.checkStatusList = this.checkStatusList;
    this.params.isCheckReport = this.isCheckReport;
    this.params.status = this.status;
    if(this.checkId) this.params.checkId = this.checkId;
    this.getList();
  },
  methods: {
    getList(nextType) {
      this.tableSelectItem = [];
      this.$refs['table'].selectAll(false);
      this.$refs['table'].getList(nextType);
      this.$emit('on-ok')
    },
    amendCommit() {
      amendCommit({id: this.tableSelectItem[0].id}).then((res) => {
        if (res.code === 200) {
          this.$Notice.success({
            title: '操作成功！'
          });
          this.$Modal.remove();
          this.getList();
        }
      });
    },
    checkProblems() {
      getCommentList({amendId: this.tableSelectItem[0].id, limit: -1}).then(res => {
        let errorList = [], content = '';
        (res.data.list || []).forEach(item => {
          item.fileList = item.fileList || [];
          const errorItem = [];
          if (item.fileList.length === 0) errorItem.push("附件")
          if (!item.riskGrade) errorItem.push("风险等级")
          if (!item.accidentType) errorItem.push("可能导致的事故类型")
          if (!item.remarksText) errorItem.push("风险描述")
          if (!item.remarksRisk) errorItem.push("风险依据")
          if (!item.remarksSuggest) errorItem.push("整改建议")
          if (errorItem.length > 0) errorList.push({itemName: item.itemName, errorItem})
        })
        if (errorList.length > 0) {
          errorList.forEach((item, index) => {
            content = content + (index + 1) + '、【' + item.itemName + '】的 <span style="color:#ff0900">' + item.errorItem.join('、') + '</span> 等未填写完整；<br/>'
          })
          this.$Modal.remove();
          window.setTimeout(() => {
            this.$Modal.confirm({
              okText: '前往修改',
              width:500,
              cancelText: '关闭',
              title: '风险必填项检查失败！',
              content: content + '请前往任务详情查看风险信息，再仔细检查并完善信息！',
              onOk: () => {
                this.detailFlag = true;
              }
            });
          }, 300);
        } else {
          this.amendCommit();
        }
      })
    },
    commit() {
      this.$Modal.confirm({
        title: '提示',
        content: '请确认项目阶段、五牌一图单体建筑物数据是否更新，提交后将无法修改报告信息！',
        okText: '确定',
        loading: true,
        cancelText: '取消',
        onOk: () => {
          this.checkProblems()
        }
      });
    },
  }
}
</script>
