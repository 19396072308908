<template>
  <div :style="{maxHeight:height-190+'px'}" style="overflow-y: auto">
    <ys-cell-group>
      <ys-cell title="报告编号" :label="detailInfo.number"/>
      <ys-cell title="创建时间" :label="detailInfo.createdAt|formatDate"/>
      <ys-cell title="项目名称" :label="detailInfo.projectName" block/>
      <ys-cell title="投保机构" :label="detailInfo.enterpriseName"/>
      <ys-cell title="承保机构" :label="detailInfo.insuranceOrgName"/>
      <ys-cell title="安全评分" style="width: 100%" :label="check.score" v-if="detailInfo.type===7"/>
      <ys-cell title="安全等级" style="width: 100%" :label="check.result|getBaseDataName('安全生产标准化等级')" v-if="detailInfo.type===7"/>
      <ys-cell title="报告结论" style="width: 100%" :label="detailInfo.desc" block/>
      <ys-cell title="整改建议措施" style="width: 100%" :label="detailInfo.suggestion" block v-if="detailInfo.type<3||detailInfo.type===9"/>
    </ys-cell-group>
    <h1 class="h1Title">报告附件</h1>
    <ys-file-list :fileList="detailInfo.fileList||[]" v-if="detailInfo.fileList" :gutter="2" :width="80" :height="80"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import baseInfo from "@/mixins/baseInfo";
import {checkInfo} from "@/api/check";

export default {
  name: "baseInfo",
  mixins: [baseInfo],
  props: {
    detailInfo: {
      type: Object, default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      check: {},
    }
  },
  computed: {
    ...mapGetters(['token', 'config']),
  },
  mounted() {
    this.getCheckInfo();
  },
  methods: {
    getCheckInfo() {
      if (!this.detailInfo.checkId) return
      checkInfo({id: this.detailInfo.checkId}).then(res => {
        if (res.code === 200) {
          this.check = res.data || {}
        }
      })
    },
  }
}
</script>

<style scoped>

</style>