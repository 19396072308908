<template>
  <div>
    <ys-page-list
        ref="table"
        action="/checkUser/List"
        keyLabel="userId"
        :params="params"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        @on-ok="getTableList"
        :multiBtn="multiBtn"
        :header="type === 1"
        :isPage="type === 1"
        :multiColumn="type === 1"
        :headerColumns="headerColumns">
        <template slot="btn" v-if="type === 1">
          <ButtonGroup>
            <!-- 在任务检查中以及之前且只有任务的专家组长可以进行操作都可添加 -->
            <Button :disabled="midData[0].status > 5 || Leader.userId !== userInfo.id" type="primary" @click="add">新增</Button>
          </ButtonGroup>
        </template>
      <template slot="detail">
        <Detail :mid="tableSelectItem.length===1?tableSelectItem[0].checkUserRecordId:''"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :title="'新增专家'">
      <do-experts :midData="midData" :isLeader="tableList.length <= 0" @on-ok="getList"></do-experts>
    </ys-modal>
    <ys-modal v-model="transferFlag" :title="'转移组长'">
      <transfer-experts :modData="tableSelectItem" :midData="midData" :isLeader="tableList.length <= 0" @on-ok="getList"></transfer-experts>
    </ys-modal>
    <ys-modal v-model="signOutFlag" title="签退">
      <signOut :midData="tableSelectItem" @on-ok="getList"></signOut>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import doExperts from './doExperts'
import transferExperts from './transferExperts'

export default {
  name: 'checkUserList',
  mixins: [pageList],
  props: {
    type: {type: Number, default: 1}
  },
  components: {
    Detail: () => import('@/views/sign/detail'),
    doExperts,
    transferExperts,
    signOut: () => import('@/views/signOut/signOut.vue')
  },
  data() {
    return {
      params: {},
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 100, title: '姓名', key: 'iname', align: 'left'},
        {width: 130, title: '电话', key: 'phone', align: 'left', renderConfig: {type: 'phoneDesensitise'}},
        {minWidth: 200, title: '地点', key: 'address', align: 'left',renderConfig: {type: 'gotoDetail'}},
        {width: 160, title: '签到时间', key: 'signTime', align: 'left'},
        {width: 80, title: '确认', key: 'isConfirm', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 80, title: '组长', key: 'isLeader', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 80, title: '签到', key: 'isSign', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 180, title: '签出时间', key: 'signOutTime', align: 'center'},
      ],
      transferFlag: false,
      Leader: {},
      signOutFlag: false,
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0]?.checkUserRecordId == 0 // 等于0时表示未打卡
            },
            {
              // 专家在未打卡之前且只有任务的专家组长可以进行操作都可以删除
              click: () => this.delItem('/check/user/Remove', {checkId: this.tableSelectItem[0].checkId, expertId: this.tableSelectItem[0].expertId}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].isSign === 1 || this.Leader?.userId !== this.userInfo.id,
              show: this.type === 1
            },
            {
              click: () => this.signOutFlag = true,
              title: '签退',
              icon: 'md-stopwatch',
              show: this.midData[0].type !== 9,
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].isSign === 0 || this.tableSelectItem[0].signOutTime !== '' || this.tableSelectItem[0].userId !== this.userInfo.id,
            },
          ]
        },
        {
          children: [
            {
              // 只有任务的专家组长可以进行操作
              click: () => this.transferFlag = true,
              iconColor: '#ed4014',
              title: '转移组长',
              icon: 'md-trash',
              disabled: this.Leader?.userId !== this.userInfo.id,
              show: this.type === 1
            },
          ]
        }
      ]
    }
  },
  async mounted() {
    this.params.type = this.type
    this.params.checkId = this.mid
    await this.getList();
  },
  methods: {
    getTableList(data) {
      this.tableList = data?.data?.list || []
      this.Leader = this.tableList.filter(item => item.isLeader === 1)[0]
    }
  }
}
</script>
