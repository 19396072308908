<template>
  <div>
    <div v-if="check.type<=2">
      <Alert type="warning" v-if="check.type === '2'">请确认是否需要调整检查项，如需要请<a href="javascript:" @click="checkItemFlag=true"> 点击此处 </a>！</Alert>
    </div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="100">
      <FormItem label="结论" prop="desc">
        <Input type="textarea" v-model="form.desc" :autosize="{minRows: 5,maxRows: 8}" placeholder="输入结论"></Input>
        <Tag @click.native="checkDesc" style="cursor: pointer" v-if="check.type<=2">模板一</Tag>
      </FormItem>
      <template v-if="form.type===7">
        <FormItem label="安全评分" prop="score">
          <InputNumber :min="0" style="width: 100%" v-model="form.score" placeholder="安全评分"></InputNumber>
        </FormItem>
        <FormItem label="安全等级" prop="result">
          <Select v-model="form.result" placeholder="安全等级">
            <Option v-for="(item,index) in baseData['安全生产标准化等级']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </template>
      <FormItem label="整改建议" prop="suggestion" v-if="check.type<=2||check.type===9">
        <Row>
          <Col span="22">
            <Input type="textarea" style="width: 100%;word-break: break-all;" v-model="form.suggestion" :autosize="{minRows: 5,maxRows: 8}" placeholder="输入整改建议"></Input>
          </Col>
          <Col span="2">
            <Button type="primary" style="width: 100%;height: 100%;" @click="quickInfoShow = true">选择</Button>
          </Col>
        </Row>
      </FormItem>
      <FormItem label="上传附件" prop="fileList">
        <ys-upload :action="config.baseURL+'/resource/UploadFile'" :defaultList.sync="detail.fileList" @on-success="bLFile" :headers="{token:token}" type="halfList"></ys-upload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('report/Add')">保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="amendFlag" :width="1000" title="生成整改单">
      <Edit @on-ok="getDetail" :parentID="checkId" :taskType="check.type"></Edit>
    </ys-modal>
    <ys-modal v-model="checkItemFlag" :width="600" title="调整检查项">
      <categoryEdit :mid="checkId"></categoryEdit>
    </ys-modal>
    <ys-modal v-model="quickInfoShow" :width="1000" :title="'选择整改建议'">
      <QuickInfo :orgId="midData.length===1?midData[0].serveOrgId:''" :type="2" :status="1" @on-ok="quickInfoData"></QuickInfo>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getReportInfo, getReportScoreInfo} from "@/api/report";
import Edit from "@/views/rectifications/Edit";
import categoryEdit from "@/views/task/categoryEdit";
import QuickInfo from "@/components/quickInfo.vue";
import {getBaseDataName} from 'ys-admin'
import {checkInfo, policyInfo} from "@/api/check";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    checkId: {type: Number, default: null},
  },
  components: {Edit, categoryEdit, QuickInfo},
  data() {
    return {
      quickInfoShow: false,
      ruleValidate: {
        desc: [
          {required: true, type: 'string', message: '请填写检查结论', trigger: 'blur,change'}
        ],
        suggestion: [
          {required: true, type: 'string', message: '请填写整改建议', trigger: 'blur,change'}
        ],
        score: [
          {required: true, type: 'number', message: '请填写安全评分', trigger: 'blur,change'}
        ],
        result: [
          {required: true, type: 'string', message: '请填写安全等级', trigger: 'change'}
        ],
      },
      checkItemFlag: false,
      amendFlag: false,
      check: {},
      detail: {},
      suggest: [],
      form: {
        id: '',
        checkId: '',
        fileList: [],
        suggestion: '',
        type: '',
        desc: '',
        score: null,
        result: '',
      },
    }
  },
  async mounted() {
    this.form.checkId = this.checkId;
    await this.getCheckDetail();
    await this.getDetail();
    await this.getCheckScore();
    await this.getPolicyInfo();
    if (!this.detail.id && this.detail.type < 3) this.checkItemFlag = true;
  },
  methods: {
    async getCheckDetail() {
      await checkInfo({id: this.checkId}).then(res => {
        this.check = res.data;
        this.form.type = this.check.type;
      });
      if (this.check.type < 3) {
        await this.$get('/comment/List', {checkId: this.check.id, isTop: false, noArea: true, limit: -1}).then((res) => {
          this.detail.commentList = res.data.list
        })
      }
    },
    async getCheckScore() {
      await getReportScoreInfo({checkId: this.form.checkId}).then(res => {
        this.check.categoryList = res.data.categoryList;
      })
    },
    async getPolicyInfo() {
      await policyInfo({id: this.check.policyId}).then(res => {
        this.detail.policy = res.data;
      })
    },
    quickInfoData(data) {
      this.form.suggestion = '根据本次安全检查发现的问题，建议总承包方牵头组织采取以下措施: ';
      data.forEach((item, index) => {
        let desc = item.desc
        if (desc.slice(-1) === '；' || desc.slice(-1) === '。') {
          desc = desc.substr(0, desc.length - 1)
        }
        this.form.suggestion = this.form.suggestion + '\n' + (index + 1) + '、' + desc + ';'
      })
    },
    bLFile(data) {
      this.detail.fileList = data || [];
      this.form.fileList = (data || []).map(item => item.id);
    },
    async getDetail() {
      if (!this.check.reportId) return;
      this.modal.spinShow = true;
      await getReportInfo({id: this.check.reportId}).then((res) => {
        this.detail = res.data;
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        });
        this.form.fileList = (this.detail.fileList || []).map(item => item.id);
        this.form.score = this.check.score;
        this.form.result = this.check.result;
        this.modal.spinShow = false;
      }).catch(() => {
        this.modal.spinShow = false;
      })
    },
    checkDesc() {
      if (this.form.desc) {
        this.$Modal.confirm({
          title: '提示',
          content: '检查结论已经存在，是否要覆盖？',
          okText: '覆盖',
          cancelText: '取消',
          onOk: () => {
            this.mode()
          }
        })
      } else {
        this.mode();
      }
    },
    mode() {
      const {commentList, policy} = this.detail;
      const {categoryList} = this.check;
      let comment = '', commentCount = '', riskGradeCount = '';
      this.baseData['风险等级'].forEach((item) => {
        let currentCommentList = (commentList || []).filter((subItem) => {
          return item.value === subItem.riskGrade
        })
        riskGradeCount = riskGradeCount + item.name + '类：' + currentCommentList.length + '项，';
      })
      categoryList.forEach((item) => {
        let currentCommentList = (commentList || []).filter((subItem) => {
          return item.id === subItem.categoryId
        })
        if (currentCommentList.length > 0) {
          commentCount = commentCount + item.name + '类：' + currentCommentList.length + '点位，';
          // 将所有依据拿出来，判断一下是否每一条依据解为是否都有其他标点符号，有则无需手动加直接拼接即可
          const SymbolList = [';', '；', '。', '.', '、', ':', '：', ',', '，']
          const remarksRiskList = currentCommentList.map((i) => i.remarksRisk)
          const isSymbol = remarksRiskList.every(item => {
            return SymbolList.some(SymbolItem => SymbolItem === item.slice(-1))
          })
          // 拼接
          comment = comment + item.iname + '类：' + isSymbol ? remarksRiskList.join('') : remarksRiskList.join('、') + '；'
        }
      })
      this.form.desc = '我司根据' + policy.companyName + '安责险风控服务的要求，于' + this.$formatDate(this.check.signTime, 'yyyy年MM月dd日') + '对' + (this.check.projectId > 0 ? this.check.project.iname : policy.enterpriseName) + '进行第 ' + (this.check.numberOfTimes || '') + ' 次安全生产责任险的隐患排查服务工作。本工程目前主要进行' + getBaseDataName('施工阶段', this.check.project.progress, false) + '，根据' + this.check.warehouseName + '，结合工程现阶段的施工情况，对工程现场的' + categoryList.map((item) => {
            return item.name
          }).join('、') + '等几大类的保证项目和一般项目进行了安全隐患的排查服务工作。' + comment +
          '\n本次施工现场安全隐患排查发现的有代表性的安全隐患点共 ' + (commentList || []).length + ' 处，' + (commentCount ? '其中' + commentCount : '') + '根据隐患的风险程度等级，' + riskGradeCount + '本次安全隐患排查所提出的安全隐患不限于上述问题，所排查的是在施工中易于发生安全隐患且有代表性的几个大类，需投保机构根据目前的工程进度和工作内容，有针对性的开展安全检查，对发现的安全隐患及时进行全面整改保证施工生产安全，杜绝安全事故的发生。'
    },
  }
}
</script>

<style lang="less" scoped>
.suggestion {

  li {
    padding: 7px 5px;
    width: 768px;
    white-space: break-spaces;
    border-bottom: 1px solid #ececec;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }
  }
}
</style>
